import React from 'react';

import { Layout } from '../../components/layouts';
import { BlogPostPreviewSection } from '../../components/sections';

const BlogPreviewPage = (): JSX.Element => {
  return (
    <Layout>
      <BlogPostPreviewSection />
    </Layout>
  );
};

export default BlogPreviewPage;
